<script setup lang="ts"></script>

<template>
	<main class="bg-nemo-sand-light h-screen">
		<NavTop :logo="true" />
		<Nav></Nav>
		<slot />
	</main>
</template>

<style scoped></style>
